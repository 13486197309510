import { LineString, Polygon } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere';

export type ReturnProps = {
  size: string;
  prefix: string;
  superscript: boolean;
};

export const getFormattedSize = (size: number): ReturnProps | undefined => {
  if (size <= 9999) {
    return {
      size: parseFloat(size.toFixed(1)).toString().replace('.', ','),
      prefix: 'm',
      superscript: true,
    };
    // else if (size <= 999999) <- swap in again if there is a need fo square kilometers
  } else {
    return {
      size: parseFloat((size / 10000).toFixed(1))
        .toString()
        .replace('.', ','),
      prefix: 'ha',
      superscript: false,
    };
  }
  // else if (size >= 1000000) {
  //   return {
  //     size: parseFloat((size / 1000000).toFixed(1))
  //       .toString()
  //       .replace('.', ','),
  //     prefix: 'km',
  //     superscript: true,
  //   };
  // }
};

//Býr til staðlað form á lengdarmælingu sem sýnilegt verður notanda
export const formatLength = function (line: LineString | Polygon) {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + ' km';
  } else {
    output = Math.round(length * 100) / 100 + ' m';
  }
  return output;
};

//Býr til staðlað form á svæðismælingu sem sýnilegt verður notanda
export const formatArea = function (polygon: Polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 10000) * 100) / 100 + ' ha';
  } else {
    output = Math.round(area * 100) / 100 + ' m\xB2';
  }
  return output;
};

export const formatAngle = function (line: LineString) {
  const p1 = line.getCoordinates()[0];
  const p2 = line.getCoordinates()[1];
  const p3 = line.getCoordinates()[2];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!p1 || !p2 || !p3) {
    return '';
  }

  const p = [
    { x: p1[0], y: p1[1] },
    { x: p2[0], y: p2[1] },
    { x: p3[0], y: p3[1] },
  ];

  const dotProduct =
    (p[0].x - p[1].x) * (p[2].x - p[1].x) + (p[0].y - p[1].y) * (p[2].y - p[1].y);
  const vectorLengthMulti =
    Math.sqrt((p[0].x - p[1].x) ** 2 + (p[0].y - p[1].y) ** 2) *
    Math.sqrt((p[2].x - p[1].x) ** 2 + (p[2].y - p[1].y) ** 2);
  const total = Math.acos(dotProduct / vectorLengthMulti) * (180 / Math.PI) || 0;

  return Math.round(total) + '°';
};
